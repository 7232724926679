<template>
  <div>
    <Layout>
      <CouponTable></CouponTable>
    </Layout>
  </div>
</template>

<script>
import Layout from "@/views/layouts/vertical.vue";
import CouponTable from "./coupon-table.vue";

export default {
  components: { Layout, CouponTable },
  data() {
    return {};
  },
};
</script>

<style></style>
