export const coupon = {
    getCouponForAdmin: {
        url: "/v1/coupon",
        method: "GET",
        params: null,
    },

    createCouponForAdmin: {
        url: "v1/admin/coupon",
        method: "POST",
        data: null,
    },
    deleteCouponForAdmin: {
        url: "v1/admin/coupon/",
        method: "DELETE",
        id: null,
    }

}