<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="right-align m-2">
        <b-button variant="primary" @click="modalShow = !modalShow">
          <i class="ri-coupon-line"></i><span class="pl-2">Add Coupon</span>
        </b-button>
      </div>
    </div>
    <b-table
      striped
      hover
      :fields="fields"
      :busy="isLoading"
      :responsive="true"
      :items="items"
    >
      <template #cell(start_date)="data">
        <span>
          {{ moment(data.item.start_date).format("LL") }}
        </span>
      </template>
      <template #cell(end_date)="data">
        <span>
          {{ data.item.end_date && moment(data.item.end_date).format("LL") }}
        </span>
      </template>
      <template #cell(usage_limit)="data">
        <span>
          {{ data.item.usage_limit !== null ? data.item.usage_limit : 0 }}
        </span>
      </template>
      <template #cell(actions)="row">
        <b-button @click="handlEdit(row.item)"> Edit </b-button>
        <b-button @click="handleDelete(row.item)"> Delete </b-button>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner type="grow" variant="primary"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-pagination
      pills
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
    >
    </b-pagination>
    <b-modal
      id="priority-modal"
      v-model="modalShow"
      hide-footer
      @hide="closeModal"
    >
      <FormulateForm @submit="handleSubmit" class="mb-4" ref="couponForm">
        <FormulateInput
          v-model="name"
          validation="required"
          name="name"
          type="text"
          label="Coupon Name"
        />
        <FormulateInput
          v-model="coupon_code"
          validation="required"
          name="coupon_code"
          type="text"
          label="Coupon Code"
        />

        <FormulateInput
          v-model="discount_type"
          name="discount_type"
          type="select"
          label="Discount Type"
          :options="discout_types"
          placeholder="Select a type"
        />
        <FormulateInput
          v-model="status"
          name="status"
          type="select"
          label="Status"
          :options="statusOption"
          placeholder="Select a status"
        />

        <FormulateInput
          v-model="discount_value"
          name="discount_value"
          type="number"
          label="Discount Value"
        />
        <FormulateInput
          v-model="on_percentage_max_discount_value"
          name="on_percentage_max_discount_value"
          type="number"
          label="Max Discount Value(%)"
        />
        <FormulateInput
          v-model="usage_limit"
          name="usage_limit"
          type="number"
          label="Use Limit"
        />
        <FormulateInput
          v-model="start_date"
          name="start_date"
          type="date"
          label="Start Date"
        />
        <FormulateInput
          v-model="end_date"
          name="end_date"
          type="date"
          label="End Date"
        />
        <b-button variant="primary" type="submit" class="m-auto"
          >Submit</b-button
        >
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { coupon } from "@/config/api/coupon";
// import DatePicker from "vue2-datepicker";
import moment from "moment";
export default {
  // components: { DatePicker },
  mounted() {
    this.getReports();
  },
  watch: {
    currentPage(val) {
      this.currentPage = val;
      this.getReports();
    },
  },
  data() {
    return {
      items: [],
      dateRange: null,
      json_data: [],
      currentPage: 1,
      rows: 2,
      startDate: null,
      endDate: null,
      perPage: 5,
      json_fields: {
        "Coupon Code": "coupon_code",
        Name: "name",
        "Booking Date": "booking_date",
        Program: "program_id.name",
        User: "user_id.fullname",
        "Booking Status": "paymentStatus",
      },
      fields: [
        { key: "name", label: "Name" },
        { key: "coupon_code", label: "Coupon Code" },
        { key: "start_date", label: "Start Date" },
        { key: "end_date", label: "End Date" },
        { key: "discount_type", label: "Discount Type" },
        { key: "discount_value", label: "Discount Value" },
        {
          key: "on_percentage_max_discount_value",
          label: "Max Discount Value (%)",
        },
        { key: "usage_limit", label: "Use Limit" },
        { key: "used_count", label: "Coupon Usage Count" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      isLoading: false,
      allReportsLoading: false,
      reportsGenerated: false,
      modalShow: false,
      name: "",
      coupon_code: "",
      discount_type: "",
      discount_value: "",
      start_date: "",
      end_date: "",
      status: "active",
      usage_limit: "",
      on_percentage_max_discount_value: "",
      dataLoading: false,
      discout_types: [
        {
          label: "Select a type",
          value: "",
        },
        {
          label: "Percentage",
          value: "percentage",
        },
        {
          label: "Fixed",
          value: "fixed",
        },
      ],
      statusOption: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "In Active",
          value: "inactive",
        },
      ],
      editModal: false,
      selectedId: null,
    };
  },

  methods: {
    closeModal() {
      this.name = "";
      this.coupon_code = "";
      this.discount_type = "";
      this.discount_value = "";
      this.start_date = "";
      this.end_date = "";
      this.usage_limit = "";
      this.status = "";
      this.on_percentage_max_discount_value = "";
      this.$bvModal.hide("priority-modal");
      this.$refs.couponForm.reset();
    },
    filterApplied() {
      this.startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      this.endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");
      this.getReports();
    },
    filterCancelled() {
      if (this.dateRange[0] == null) {
        this.startDate = null;
        this.endDate = null;
        this.getReports();
      }
    },
    async getReports() {
      this.isLoading = true;
      this.tableData = [];
      const api = coupon.getCouponForAdmin;
      api.params = { page: this.currentPage, limit: this.perPage };
      await this.generateAPI(api)
        .then((res) => {
          this.items = res.data.data.coupons;

          this.rows = res.data.data.totalCount;
          this.perPage = res.data.data.limit;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
          console.log("Loaded");
        });
    },

    async handleSubmit() {
      const body = {
        name: this.name,
        coupon_code: this.coupon_code,
        discount_type: this.discount_type,
        discount_value: this.discount_value,
        start_date: this.start_date,
        end_date: this.end_date,
        on_percentage_max_discount_value: this.on_percentage_max_discount_value,
        usage_limit: this?.usage_limit,
        status: this?.status,
      };
      if (this.editModal) {
        body.id = this.selectedId;
      }
      let api = coupon.createCouponForAdmin;
      //   api.id = this.$route.params.id;/*  */
      api.data = body;
      this.dataLoading = true;
      await this.generateAPI(api)
        .then((res) => {
          if (res) {
            this.$bvToast.toast(res?.data?.message, {
              title: "Coupons",
              variant: "success", // Use 'danger' for error toasts
              solid: true,
            });
            this.getReports();
            this.modalShow = false;
            this.closeModal();
          }
        })
        .catch((err) => {
          if (err?.response?.data?.data) {
            err?.response?.data?.data?.forEach((message) => {
              this.$bvToast.toast(message, {
                title: "Coupons",
                variant: "danger", // Use 'danger' for error toasts
                solid: true,
              });
            });
          } else if (err?.response?.data?.message) {
            this.$bvToast.toast(err?.response?.data?.message, {
              title: "Coupons",
              variant: "danger", // Use 'danger' for error toasts
              solid: true,
            });
          }
        })
        .finally(() => {
          this.dataLoading = false;
          this.editModal = false;
          this.selectedId = null;
        });
    },
    handlEdit(row) {
      this.name = row?.name;
      this.coupon_code = row?.coupon_code;
      this.discount_type = row?.discount_type;
      this.discount_value = row?.discount_value;
      this.start_date = moment(row?.start_date).format("YYYY-MM-DD");
      this.end_date = moment(row?.end_date).format("YYYY-MM-DD");
      this.on_percentage_max_discount_value =
        row?.on_percentage_max_discount_value;
      this.status = row?.status;
      this.usage_limit = row?.usage_limit;
      this.modalShow = true;
      this.editModal = true;
      this.selectedId = row?._id;
    },
    handleDelete(item) {
      const api = coupon.deleteCouponForAdmin;
      api.id = item._id;
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the coupont", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.generateAPI(api).then(() => {
              this.page = 1;
              this.getReports();
            });
          }
        });
    },
  },
};
</script>

<style></style>
